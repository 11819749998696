import { j as e } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as P } from "react";
import { B as f } from "./Button-DhTfdjdw.js";
import { C as B } from "./Checkbox.component-BwA9G7cC.js";
import { D as j } from "./DropdownMenu-D5Lc6MaH.js";
import { I as r } from "./Icon-VZwz_Tkb.js";
import { P as O } from "./ProgressBar-RMvKyuSw.js";
import { S as b } from "./Stack-9Jnn74Nm.js";
import { T as i } from "./Text-DOFuHqUo.js";
import { T as o } from "./Tooltip-ae2E5kx6.js";
import { u as R } from "./useMediaQuery-BLIs2rKV.js";
import { F as k } from "./FileIcon.component-CTY6WmOB.js";
import { c as L } from "./utils-CJ9afRe1.js";
import { S as t } from "./ScreenSizeDropdownMenu.component-C65ksMmD.js";
const E = (n) => n.isDesktop ? {
  withPortal: n.withPortal,
  align: "start",
  side: "bottom",
  alignOffset: 0
} : {}, ee = P((n, v) => {
  const w = R("(min-width: 768px)"), { className: N, ...S } = n, {
    checked: d,
    isDragging: C,
    onCancelUpload: y,
    onCheckFilesFromOrToFile: M,
    onMove: a,
    onDelete: z,
    onOpenInNewTab: W,
    onMoveToMyShoeBox: c,
    onDoubleClick: h,
    onClick: m,
    onDownload: x,
    onCopy: g,
    onRename: I,
    onSendMessage: p,
    onCopyLinks: u,
    file: s,
    withPortal: T,
    onCheckChange: D,
    ...F
  } = S;
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      "aria-checked": d,
      "data-dragged": C,
      className: L(
        "flex data-[dragged=true]:opacity-10  aria-checked:bg-black-20 px-1 gap-1 bg-black-10 hover:bg-black-20 group rounded-md transition-colors items-center",
        N
      ),
      children: [
        /* @__PURE__ */ e.jsxs(
          "div",
          {
            className: "flex flex-col flex-1 overflow-hidden h-11 py-1.5 group/icon",
            ref: v,
            ...F,
            children: [
              /* @__PURE__ */ e.jsxs("div", { className: "flex items-center gap-2 flex-1  shrink-0  justify-center", children: [
                /* @__PURE__ */ e.jsx(
                  "button",
                  {
                    className: "hidden sm:flex items-center cursor-move text-gray-neutral-100  opacity-0 h-7 group-hover:opacity-100 transition-opacity",
                    children: /* @__PURE__ */ e.jsx(r, { name: "DragHandle", width: 8, height: 14, className: "shrink-0" })
                  }
                ),
                /* @__PURE__ */ e.jsx(
                  B,
                  {
                    className: "bg-white opacity-0 group-hover/icon:opacity-100 data-active:opacity-100",
                    checked: d,
                    size: "sm",
                    "data-ui": d ? "active" : "",
                    "data-testid": "multi-select-checkbox",
                    onClick: (l) => {
                      l.shiftKey && (M(s), l.preventDefault());
                    },
                    onCheckedChange: (l) => D(l, s)
                  }
                ),
                /* @__PURE__ */ e.jsx(k, { fileName: s.documentName, className: "shrink-0" }),
                /* @__PURE__ */ e.jsx(o.Provider, { children: /* @__PURE__ */ e.jsxs(
                  o,
                  {
                    delayDuration: 1e3,
                    children: [
                      /* @__PURE__ */ e.jsx(o.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                        i,
                        {
                          select: !1,
                          tabIndex: 0,
                          role: "button",
                          onDoubleClick: () => h == null ? void 0 : h(s),
                          onClick: () => m == null ? void 0 : m(s),
                          size: "sm",
                          className: "flex-1 inline-flex items-center min-w-0",
                          children: /* @__PURE__ */ e.jsx("span", { className: "truncate", children: s.title })
                        }
                      ) }),
                      /* @__PURE__ */ e.jsx(o.Portal, { children: /* @__PURE__ */ e.jsx(o.Content, { children: s.title }) })
                    ]
                  }
                ) })
              ] }),
              s.uploading && /* @__PURE__ */ e.jsx(
                O,
                {
                  height: "h-1",
                  progressBackGroundColor: "bg-gray-neutral-35",
                  className: "ml-9 flex-1 flex",
                  showLegend: !1,
                  items: [
                    {
                      title: "",
                      value: s.uploadProgress
                    }
                  ]
                }
              )
            ]
          }
        ),
        s.uploading && /* @__PURE__ */ e.jsx(
          f,
          {
            title: "Cancel upload",
            "aria-label": "Cancel upload",
            onClick: () => y(s),
            variant: "ghost",
            className: "inline-flex items-center",
            size: "sm",
            children: /* @__PURE__ */ e.jsx(r, { strokeWidth: 1.5, name: "Cancel", width: 20, height: 20 })
          }
        ),
        !s.uploading && /* @__PURE__ */ e.jsxs("div", { className: "lg:min-w-0 lg:w-0 justify-end group-hover:w-auto flex items-center overflow-hidden gap-2 shrink-0", children: [
          a && /* @__PURE__ */ e.jsx(
            f,
            {
              title: "Move file",
              "aria-label": "Move file",
              onClick: () => a(s),
              variant: "secondary",
              className: "hidden sm:inline-flex rounded-full bg-transparent hover:bg-gray-neutral-50 w-6 h-6 focus:bg-gray-neutral-50 p-0.5",
              children: /* @__PURE__ */ e.jsx(r, { strokeWidth: 1.5, name: "FolderMoveUp", width: 17, height: 14 })
            }
          ),
          /* @__PURE__ */ e.jsxs(t, { modal: !1, children: [
            /* @__PURE__ */ e.jsx(t.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
              f,
              {
                title: "More options",
                "aria-label": "More options",
                variant: "secondary",
                className: "rounded-full hover:bg-gray-neutral-50 w-6 h-6 focus:bg-gray-neutral-50 p-0.5 bg-transparent inline-flex",
                children: /* @__PURE__ */ e.jsx(r, { strokeWidth: 2, name: "MoreVert", width: 20, height: 20 })
              }
            ) }),
            /* @__PURE__ */ e.jsxs(
              t.Content,
              {
                ...E({ isDesktop: w, withPortal: T }),
                className: "z-dialog gap-2 min-w-72",
                children: [
                  !w && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                    /* @__PURE__ */ e.jsxs(
                      "div",
                      {
                        className: "flex gap-2 h-9items-center rounded-md px-2 pt-2",
                        children: [
                          /* @__PURE__ */ e.jsx(k, { fileName: s.documentName, className: "shrink-0" }),
                          /* @__PURE__ */ e.jsx(i, { size: "sm", truncate: !0, className: "flex-1", children: s.title })
                        ]
                      }
                    ),
                    /* @__PURE__ */ e.jsx(j.Separator, {})
                  ] }),
                  /* @__PURE__ */ e.jsxs(
                    t.Item,
                    {
                      onSelect: () => W(s),
                      className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                      children: [
                        /* @__PURE__ */ e.jsx(r, { name: "MultiWindow", strokeWidth: 2, width: 18, height: 18 }),
                        " ",
                        /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Open in New Tab" })
                      ]
                    }
                  ),
                  /* @__PURE__ */ e.jsxs(
                    t.Item,
                    {
                      onSelect: () => I(s),
                      className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                      children: [
                        /* @__PURE__ */ e.jsx(r, { name: "Edit", strokeWidth: 2, width: 18, height: 18 }),
                        " ",
                        /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Rename" })
                      ]
                    }
                  ),
                  /* @__PURE__ */ e.jsx(j.Separator, {}),
                  (a || g || c) && /* @__PURE__ */ e.jsxs(t.Sub, { children: [
                    /* @__PURE__ */ e.jsx(t.SubTrigger, { className: "gap-2 h-9 flex items-center px-2", asChild: !0, children: /* @__PURE__ */ e.jsxs(b, { row: !0, space: "sm", className: "flex-1 font-medium text-sm", children: [
                      /* @__PURE__ */ e.jsx(r, { name: "ArrowEmailForward", strokeWidth: 2, width: 18, height: 18 }),
                      "Share"
                    ] }) }),
                    /* @__PURE__ */ e.jsxs(
                      t.SubContent,
                      {
                        className: "z-dialog gap-2 max-w-full sm:w-72 flex flex-col",
                        children: [
                          a && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => a(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "FolderMoveUp",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Move to..." })
                              ]
                            }
                          ),
                          g && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => g(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "Copy",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Copy to..." })
                              ]
                            }
                          ),
                          c && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => c(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "Box",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Move to My Shoebox" })
                              ]
                            }
                          )
                        ]
                      }
                    )
                  ] }),
                  (p || x || u) && /* @__PURE__ */ e.jsxs(t.Sub, { children: [
                    /* @__PURE__ */ e.jsx(t.SubTrigger, { className: "gap-2 h-9 flex items-center px-2", asChild: !0, children: /* @__PURE__ */ e.jsxs(b, { row: !0, space: "sm", className: "flex-1 text-sm font-medium", children: [
                      /* @__PURE__ */ e.jsx(r, { name: "FolderOpenOutline", strokeWidth: 2, width: 18, height: 18 }),
                      "Organize"
                    ] }) }),
                    /* @__PURE__ */ e.jsxs(
                      t.SubContent,
                      {
                        className: "z-dialog gap-2 max-w-full sm:w-72 flex flex-col",
                        children: [
                          p && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => p(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "MessageText",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Send Message" })
                              ]
                            }
                          ),
                          x && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => x(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "DownloadMenu",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Download" })
                              ]
                            }
                          ),
                          u && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => u(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "Link",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Copy Link" })
                              ]
                            }
                          )
                        ]
                      }
                    )
                  ] }),
                  /* @__PURE__ */ e.jsx(j.Separator, {}),
                  /* @__PURE__ */ e.jsxs(
                    t.Item,
                    {
                      onSelect: () => z(s),
                      className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2 text-destructive",
                      children: [
                        /* @__PURE__ */ e.jsx(r, { name: "Trash", strokeWidth: 2, width: 18, height: 18 }),
                        " ",
                        /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", variant: "inherit", children: "Remove" })
                      ]
                    }
                  )
                ]
              }
            )
          ] })
        ] })
      ]
    }
  );
});
export {
  ee as S
};
